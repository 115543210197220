import type BaseGridControl from 'o365.controls.Grid.BaseGrid.ts';
import GridArrayData from 'o365.controls.Grid.extension.ArrayData.ts';
import { ref, computed, watch } from 'vue';

export async function useArrayData(dataRef: Ref<any[]>, gridControl: Ref<BaseGridControl>) {

    const arrayData = GridArrayData.extend(gridControl.value);

    //--- Filter ---
    let filteredRef = dataRef;
    if (!gridControl.value.props.disableFilterRow && !gridControl.value.props.noHeader) {
        const { useBaseFilter } = await import('o365.controls.Grid.extension.BaseFilter.ts');
        const { filteredData } = useBaseFilter({
            dataRef: dataRef,
            gridControl: gridControl.value,
        });

        filteredRef = filteredData;
    }

    //--- Sort ---
    let sortedRef = filteredRef;
    if (!gridControl.value.props.disableSorting) {
        sortedRef = ref([...filteredRef.value]);
        const sortedArray = computed(() => {
            return [...filteredRef.value].sort();
        });

        const applySort = () => {
            const sortOrder = gridControl.value.columns.columns.filter(x => x.sort != null);

            if (sortOrder.length > 0) {
                let sortResult = [...filteredRef.value];
                sortOrder.sort((a, b) => b.sortOrder - a.sortOrder);
                sortOrder.forEach(item => {
                    sortResult.sort(sortByKey(item.field, item.sort, item.type));
                });
                sortedRef.value = sortResult;
            } else {
                sortedRef.value = filteredRef.value;
            }
        };

        watch(sortedArray, () => {
            applySort();
        });

        gridControl.value.arrayData['_applySort'] = applySort;

        const sortByKey = (key: string, order: 'asc' | 'desc', type: string = 'string') => {
            return function (a: any, b: any) {
                var a = a[key];
                var b = b[key];
                if (type === "string") {
                    if (a && b) {
                        if (a !== null) a = a.toString().toUpperCase();
                        if (b !== null) b = b.toString().toUpperCase();
                    }
                } else if (['datetime', 'date'].includes(type)) {
                    a = new Date(a);
                    b = new Date(b);
                }

                switch (type) {
                    case "number":
                        return order === "asc" ? (a - b) : (b - a);

                    case "string":
                    case "date":
                    case "datetime":
                    case "uniqueidentifier":
                        return order === "asc" ? ((a < b) ? -1 : (b < a) ? 1 : 0) : ((a > b) ? -1 : (b > a) ? 1 : 0);

                    default:
                        return 0;
                }
            };
        }
    }

    //--- Virtual Scroll ---
    let virtualizedRef = sortedRef;
    if (!gridControl.value.props.disableVirtualScroll) {
        const VirtualScroll = await import('o365.vue.composables.VirtualScroll.ts');
        const sortedArray = computed(() => {
            return [...sortedRef.value].sort();
        });
        const { scrollData, handleScroll } = VirtualScroll.default({
            dataRef: sortedRef,
            itemSize: gridControl.value.props.rowHeight,
            itemsToRender: gridControl.value.props.itemsToRender,
            elementRef: computed(() => { return gridControl.value.viewport; }),
            recycleList: true,
            watchRef: sortedArray,
            mountedOverride: (fn: Function) => {
                if (gridControl.value.isMounted) {
                    fn();
                } else {
                    gridControl.value.events.on('mounted', () => { fn(); })
                }
            },
            unmountedOverride: (fn: Function) => {
                if (gridControl.value.isBeingUnmounted) {
                    fn();
                } else {
                    gridControl.value.events.on('unmounted', () => { fn(); })
                }
            },
        });
        gridControl.value.viewportScrollHandler = handleScroll;

        virtualizedRef = scrollData;
    }

    //--- Output ---
    const returnData = computed(() => {
        gridControl.value.arrayData.data = sortedRef.value;
        return gridControl.value.isMounted
            ? gridControl.value.props.disableVirtualScroll
                ? virtualizedRef.value.map((item, index) => ({ item: item, index: index, pos: gridControl.value.props.rowHeight * index }))
                : virtualizedRef.value
            : [];
    });

    // Watch and update total data length
    watch(() => dataRef.value?.length, (newLength: number) => {
        arrayData.rowCount = newLength;
    });

    arrayData.rowCount = dataRef.value?.length;

    return returnData;
}

interface Ref<T> {
    value: T
};